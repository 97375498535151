import React, { FC } from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { NarrowContainer } from "../components/container";
import styled from "styled-components";
// @ts-ignore
import thumbsUp from "../assets/thumbs-up.svg";
import { ServiceTemplateQuery } from "../../graphql-types";
// import { Services } from "../components/services";
import { Projects } from "../components/projects";

type Props = {
  data: ServiceTemplateQuery;
};

export const query = graphql`
  query ServiceTemplate($id: String!) {
    wpService(id: { eq: $id }) {
      title
      content
    }

    wp {
      globaal {
        globalCustomFields {
          email
          phoneNumber
        }
      }
    }
  }
`;

const ServiceTemplate: FC<Props> = (props) => {
  return (
    <Layout>
      <SEO title={props.data.wpService?.title || ""} />
      <NarrowContainer style={{ marginTop: "3em", marginBottom: 100 }}>
        <Title>{props.data.wpService?.title}</Title>

        <div
          dangerouslySetInnerHTML={{
            __html: props.data.wpService?.content || "",
          }}
        />
      </NarrowContainer>

      <Projects
        title="Gerelateerde projecten"
        content={`Krijg een indruk van onze kwaliteiten op het gebied van ${props.data.wpService?.title?.toLowerCase()} door een blik te werpen op enkele gerealiseerde projecten.`}
        containerWidth="narrow"
        filterByServices={[props.data.wpService?.title || ""]}
      />

      <NarrowContainer style={{ marginBottom: "3.5em" }}>
        <CTA>
          <div>
            <img src={thumbsUp} />
          </div>
          <div
            style={{
              textTransform: "uppercase",
              fontWeight: 700,
              lineHeight: "40px",
            }}
          >
            Kunnen we jou helpen?
          </div>
          <div style={{ fontFamily: "Poppins, sans-serif", fontSize: 30 }}>
            Bel:{" "}
            <a
              href={`tel:${props.data.wp?.globaal?.globalCustomFields?.phoneNumber}`}
            >
              {props.data.wp?.globaal?.globalCustomFields?.phoneNumber}
            </a>{" "}
            of stuur{" "}
            <a
              href={`mailto:${props.data.wp?.globaal?.globalCustomFields?.email}`}
              style={{ borderBottom: "1px solid #fff" }}
            >
              een e-mail
            </a>
          </div>
        </CTA>
      </NarrowContainer>

      {/*<Services />*/}
    </Layout>
  );
};

export default ServiceTemplate;

const Title = styled.h1`
  font-family: Poppins, sans-serif;
  font-weight: 100;
  color: #0b0b0b;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0.25em;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 35px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 60px;
  }
`;

const CTA = styled.div`
  background-color: #00a88e;
  color: #fff;
  margin-top: 40px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
